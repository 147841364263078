import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpStatusCode} from '@angular/common/http';
import {WebException} from '../models/index';
import {TokenService} from '../services/token.service';
import {Observable, throwError as observableThrowError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {ModalService} from '../services/modal.service';
import {ErrorCodeConstants} from '../constants/error-code-constants';
import {SessionService} from '../services/session.service';
import {ModalSelectors} from '../constants/modal-selectors';
import {ApiEndpoints} from '../enums/api-endpoints.enum';

declare let $: any;

@Injectable()
export class InterceptedHttp implements HttpInterceptor {

    constructor(private tokenService: TokenService, private modalService: ModalService,
                private sessionService: SessionService) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        switch (request.url) {
            case ApiEndpoints.AuthenticationEntryPoint:
            case ApiEndpoints.ExternalCheckFileContent:
            case ApiEndpoints.InternalCheckFileContent:
                request = request.clone({setParams: this.setSessionToken()});
                break;
            default:
                request = request.clone({
                    setHeaders: this.addHeaders(),
                    setParams: this.setSessionToken()
                });
                break;
        }

        return next.handle(request).pipe(
            catchError((exception) => {

                const error = {
                    listError: [],
                    listWarning: []
                };

                switch (exception.status) {
                    case HttpStatusCode.Unauthorized:
                        error.listWarning.push(ErrorCodeConstants.INVALID_CREDENTIALS);
                        break;
                    case HttpStatusCode.Forbidden :
                        this.openAuthenticationModal();
                        break;
                    default :
                        if (exception && exception.error) {
                            return observableThrowError(new WebException(exception.error, exception.status));
                        }
                        error.listError.push('ERROR_TECHNICAL_PROBLEMS');
                        break;
                }

                return observableThrowError(new WebException(error, exception.status));
            })
        );
    }

    private openAuthenticationModal(): void {
        this.modalService.emitShowAuthenticatePasswordForm(true);

        if (!$('#modal-password').length) {
            return;
        }

        this.modalService.openModal(null, null, ModalSelectors.PASSWORD_MODAL);
    }

    private addHeaders() {

        const isIE11Max = /msie\s|trident\//i.test(window.navigator.userAgent);

        const headers = {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            'Cache-Control': 'private, no-cache, no-store',
            Pragma: 'private, no-cache, no-store'
        };

        if (isIE11Max) {

            Object.assign(headers, {'If-Modified-Since': '0'});
        }

        return headers;
    }

    private setSessionToken() {

        const httpParams = {};
        const token = this.tokenService.getToken();

        if (token) {
            this.sessionService.setLastSessionUpdate(Date.now());
            document.cookie = this.tokenService.TOKEN_NAME + '=' + token;
        }

        return httpParams;
    }
}
