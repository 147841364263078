<div id="modal-create-profile" class="modal" tabindex="-1" role="dialog" aria-labelledby="modal-password-label"
     aria-describedby="modal-password-desc">
    <div class="modal-dialog">
        <form [formGroup]="form" ngForm novalidate (submit)="submitForm()" role="form">
            <div class="modal-content" role="document" tabindex="0">
                <div class="modal-header">
                    <h2 class="modal-title" id="modal-password-label" data-loop="start" tabindex="0">
                        {{ 'ODC_CREATE_PROFIL_TITLE' | translate }}
                    </h2>
                </div>
                <div class="modal-body">
                    <ng-container *ngIf="displayCreateProfileFormGroup">

                        <app-http-error [errors]="httpErrors"></app-http-error>

                        <div class="form-horizontal m-t-4 m-b-4">
                            <div *ngIf="!emailSent">
                                <p>{{ 'PROFIL_CREATE_EMAIL_SEND' | translate }} <b>{{ email }}</b></p>
                                <br>
                                <div class="form-group">
                                    <label for="securityQuestion" class="col-md-6 control-label">
                                        <b>{{'PROFIL_SECURITY_QUESTION' | translate}}</b>
                                    </label>
                                    <div class="col-md-18" [class.has-error]="isFieldInError('securityQuestion')">
                                        <label *ngIf="isFieldInError('securityQuestion')" for="securityQuestion">
                                            <app-error [form]="form" [submitted]="isFormSubmitted()" [path]="'securityQuestion'"></app-error>
                                        </label>
                                        <select id="securityQuestion" class="custom-select form-control"
                                                name="securityQuestion"
                                                formControlName="securityQuestion">
                                            <option [ngValue]="null">{{ 'GLOBAL_SELECT' | translate }}</option>
                                            <option *ngFor="let item of securityQuestionList" [value]="item.itemValue">
                                                {{item.itemLabel}}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label for="securityAnswer" class="col-md-6 control-label">
                                        <b>{{'PROFIL_SECURITY_ANSWER' | translate}}</b>
                                    </label>
                                    <div class="col-md-18" [class.has-error]="isFieldInError('securityAnswer')">
                                        <label *ngIf="isFieldInError('securityAnswer')" for="securityAnswer">
                                            <app-error [form]="form" [submitted]="isFormSubmitted()" [path]="'securityAnswer'"></app-error>
                                        </label>
                                        <input id="securityAnswer" class="form-control"
                                               name="securityAnswer" type="text"
                                               formControlName="securityAnswer" maxlength="64">
                                    </div>
                                </div>
                                <br>
                                <app-recaptcha *ngIf="renderRecaptcha" [containerId]="'recaptcha-create-profile'"
                                               [langChange]="translate.onLangChange" [lang]="translate.currentLang"></app-recaptcha>
                            </div>

                            <p *ngIf="emailSent">{{ ('PROFIL_CREATE_EMAIL_SENT' | translate) }} <b>{{ email }}</b></p>
                        </div>
                    </ng-container>
                </div>

                <div class="modal-footer">
                    <div class="btn-resp btn-resp-1colonne-inline" *ngIf="!emailSent">
                        <div class="btn-resp-col btn-resp-demi">
                            <button class="btn btn-primary loadable"
                                    title="{{'BTN_CONTINUE'|translate}}"
                                    type="submit"
                                    data-focus="initial"
                                    [class.loading]="loading"
                                    [attr.disabled]="loading ? true : null">
                                {{'BTN_CONTINUE'|translate}}
                            </button>
                        </div>
                        <div class="btn-resp-col btn-resp-demi">
                            <button (click)="onClosingModal()" class="btn btn-default" data-dismiss="modal" title="{{'BTN_CANCEL'|translate}}"
                                    type="button">
                                {{'BTN_CANCEL'|translate}}
                            </button>
                        </div>
                    </div>
                    <button *ngIf="emailSent" (click)="onClosingModal()" class="btn btn-primary" data-dismiss="modal"
                            title="{{'BTN_CLOSE'|translate}}">
                        {{'BTN_CLOSE'|translate}}
                    </button>
                </div>

                <div class="sr-only sr-only-focusable" tabindex="0" data-loop="end">{{'MODAL_END'|translate}}</div>
            </div>
        </form>
    </div>
</div>
