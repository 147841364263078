import {Component, OnInit, ViewChild} from '@angular/core';
import {BaseNavComponent} from '../../questionnaire/base-nav.component';
import {FormBuilder, Validators} from '@angular/forms';
import {NavigationService} from '../../../../components/services/navigation.service';
import {ModalService} from '../../../../components/services/modal.service';
import {FormService} from '../../../../components/services/form.service';
import {TranslateService} from '@ngx-translate/core';
import {ProfileService} from '../../../../components/services/profile.service';
import {VmdConstants} from '../../../../components/constants/vmd-constants';
import {VmdValidators} from '../../../../components/validators/vmd-validators';
import {SessionService} from '../../../../components/services/session.service';
import {AuthService} from '../../../../components/services/auth.service';
import {ClientLoginBean} from '../../../../components/models/clientLoginBean';
import {PostLoginActionService} from '../../../../components/services/post-login-action.service';
import {UserLogin} from '../../../../components/models/user-login';
import {RecaptchaComponent} from '../../../login/recaptcha.component';
import {WebException} from '../../../../components/models';

declare let $: any;

@Component({
    selector: 'app-modal-password',
    templateUrl: './modal-password.component.html'
})
export class ModalPasswordComponent extends BaseNavComponent implements OnInit {

    @ViewChild(RecaptchaComponent) recaptcha: RecaptchaComponent;

    displayAuthenticatePasswordGroup = false;
    displayDefinePasswordGroup = false;
    displayCustomerService = false;
    customerServiceMsg: string;
    showInputPassword = true;
    loading = false;
    renderRecaptcha: boolean = false;
    private passwordAttempt = 0;

    constants: any = VmdConstants;
    httpErrors: any;

    constructor(public navigationService: NavigationService,
                protected fb: FormBuilder,
                public formService: FormService,
                public translate: TranslateService,
                protected modalService: ModalService,
                private profileService: ProfileService,
                private sessionService: SessionService,
                private authService: AuthService,
                private postLoginActionService: PostLoginActionService) {
        super(navigationService, fb, formService, translate);
    }

    ngOnInit() {

        this.form = this.fb.group({

            authenticatePasswordGroup: this.fb.group({
                password: [null, [Validators.required]]
            }),

            definePasswordGroup: this.fb.group({
                newPassword: [null, [Validators.required, Validators.minLength(VmdConstants.PASSWORD_LENGTH_MIN)]],
                confirmNewPassword: [null, [Validators.required]]
            }, {validator: VmdValidators.passwordMatchValidator()}),

            passwordStrength: [null, [Validators.min(VmdConstants.PASSWORD_STRENGTH_MIN)]]
        });

        this.modalService.showAuthenticatePasswordForm.subscribe((value: boolean) => {
            this.resetModal();
            if (this.passwordAttempt >= 3) {
                this.displayCustomerService = true;
                this.customerServiceMsg = 'PROFIL_SERVICE_CLIENTELE';
            } else {
                this.displayAuthenticatePasswordGroup = value;
                this.updateControlEnabled(this.form.get('authenticatePasswordGroup'), true);
            }
        });

        this.modalService.showDefinePassword.subscribe(() => {
            this.resetModal();
            this.displayDefinePasswordGroup = true;
            this.updateControlEnabled(this.form.get('definePasswordGroup'), true);
            this.updateControlEnabled(this.form.get('passwordStrength'), true);
        });

        this.renderRecaptcha = true;
    }

    submitModalForm() {
        this.markAsTouched(this.form);
        if (this.form.valid) {
            if (this.displayAuthenticatePasswordGroup) {
                this.authenticatePassword();
            } else if (this.displayDefinePasswordGroup) {
                this.definePassword();
            }
        }
    }

    resetModal() {
        this.httpErrors = null;
        this.displayAuthenticatePasswordGroup = false;
        this.updateControlEnabled(this.form.get('authenticatePasswordGroup'), false);
        this.clearAuthenticatePasswordGroupValue();

        this.displayDefinePasswordGroup = false;
        this.updateControlEnabled(this.form.get('definePasswordGroup'), false);
        this.clearDefinePasswordGroupValue();

        this.updateControlEnabled(this.form.get('passwordStrength'), false);
        this.form.get('passwordStrength').setValue(null);

        this.displayCustomerService = false;
        this.showInputPassword = true;

        this.resetRecaptcha();
    }

    resetRecaptcha() {
        if (this.recaptcha) {
            this.recaptcha.reset();
        }
    }

    clearAuthenticatePasswordGroupValue() {
        this.form.get('authenticatePasswordGroup.password').setValue(null);
    }

    clearDefinePasswordGroupValue() {
        this.form.get('definePasswordGroup.newPassword').setValue(null);
        this.form.get('definePasswordGroup.confirmNewPassword').setValue(null);
    }

    onPasswordStrengthChanges(value: number) {
        if (value != null) {
            this.form.get('passwordStrength').setValue(value);
        }
    }

    authenticatePassword() {
        this.loading = true;
        this.httpErrors = null;
        this.authService.authenticate(ClientLoginBean.fromObject({
            email: this.formService.getForm().requesters[0].userEmail,
            password: this.form.get('authenticatePasswordGroup.password').value,
            reCaptchaResponse: this.recaptcha.getRecaptchaToken()
        }))
            .subscribe(
                (data: UserLogin) => {
                    if (data.temporaryPasswordIndicator) {
                        this.modalService.emitShowAuthenticateTempPassword();
                    } else {
                        this.onAuthenticatePasswordSuccess();
                    }
                },
                (error: WebException) => {
                    this.onAuthenticatePasswordError(error);
                }
            ).add(() => {
                this.loading = false;
            }
        );
    }

    onAuthenticatePasswordSuccess() {
        this.postLoginActionService.executePendingAction();
        this.closeModal();
    }

    onAuthenticatePasswordError(error) {
        this.passwordAttempt += 1;
        if (this.passwordAttempt >= 3) {
            this.showInputPassword = false;
        } else {
            this.httpErrors = error;
        }

        this.resetRecaptcha();
    }

    definePassword() {
        this.loading = true;
        this.profileService.definePassword(
            this.form.get('definePasswordGroup.newPassword').value,
            this.form.get('definePasswordGroup.confirmNewPassword').value
        ).subscribe(
            () => {
                this.onDefinePasswordSuccess();
            }, (error) => {
                this.onDefinePasswordError(error);
            }).add(() => {
            this.loading = false;
        });
    }

    onDefinePasswordSuccess() {
        this.postLoginActionService.executePendingAction();
        this.closeModal();
    }

    onDefinePasswordError(error) {
        this.httpErrors = error;
    }

    closeModal() {
        if (!$('#modal-password').length) {
            return;
        }
        this.ngForm.resetForm();
        $('#modal-password').modal('hide');
    }

    openForgotPasswordModal() {
        this.closeModal();
        this.modalService.emitShowForgotPassword(true);
        this.modalService.openModal(null, null, '#modal-forgot-password');
    }

    openCreateProfileModal() {
        this.closeModal();
        this.modalService.emitShowCreateProfile(true);
        this.modalService.openModal(null, null, '#modal-create-profile');
    }
}
