<div id="modal-password" class="modal" tabindex="-1" role="dialog" aria-labelledby="modal-password-label"
     aria-describedby="modal-password-desc">
    <div class="modal-dialog">
        <form [formGroup]="form" ngForm novalidate (submit)="submitModalForm()" role="form">
            <div class="modal-content" role="document" tabindex="0">
                <div class="modal-header">
                    <h2 class="modal-title" id="modal-password-label" data-loop="start" tabindex="0">
                        {{ 'ODC_MODAL_CONNEXION_TITLE' | translate }}
                    </h2>
                </div>

                <!--profile authentication-->
                <ng-container *ngIf="displayAuthenticatePasswordGroup">
                    <div class="modal-body" [attr.id]="'modal-password-desc'">
                        <div formGroupName="authenticatePasswordGroup">

                            <div *ngIf="showInputPassword">
                                <p>{{'PROFIL_ENTER_A_PASSWORD'|translate}}</p>

                                <div [class.has-error]="httpErrors != null">
                                    <span class="help-block" tabindex="0">
                                        <app-http-error [errors]="httpErrors" [isGenericBlocError]="false"></app-http-error>
                                    </span>
                                </div>

                                <div class="form-horizontal m-t-4 m-b-4">
                                    <div class="form-group">
                                        <label for="password" class="col-md-6 control-label">
                                            <b>{{'PROFIL_PASSWORD_AUTHENTICATE_PASSWORD' | translate}}</b>
                                        </label>
                                        <div class="col-md-18" [class.has-error]="isFieldInError('authenticatePasswordGroup.password')">
                                            <label *ngIf="isFieldInError('authenticatePasswordGroup.password')" for="password">
                                                <app-error [form]="form" [submitted]="isFormSubmitted()"
                                                           [path]="'authenticatePasswordGroup.password'">
                                                </app-error>
                                            </label>
                                            <input id="password" class="form-control" name="password" type="password"
                                                   maxlength="32" formControlName="password">
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <p [innerHTML]="'PROFIL_PASSWORD_FORGOT_TITLE' | translate"></p>
                            <div class="text-center">
                                <a href="javascript:void(0)" class="lien-action"
                                   (click)="openForgotPasswordModal()">
                                    {{'PROFIL_PASSWORD_FORGOT_LINK'|translate}}
                                </a>
                            </div>
                            <p [innerHTML]="'ODC_MODAL_CONNEXION_REGISTER_DESC' | translate"></p>
                            <div class="text-center">
                                <a href="javascript:void(0)" class="lien-action"
                                   (click)="openCreateProfileModal()">
                                    {{'PROFIL_CREATE_LINK'|translate}}
                                </a>
                            </div>
                            <div>
                                <br>
                                <app-recaptcha *ngIf="renderRecaptcha"
                                               [containerId]="'recaptcha-login-save'"
                                               [langChange]="translate.onLangChange"
                                               [lang]="translate.currentLang"
                                ></app-recaptcha>
                            </div>

                        </div>
                    </div>
                </ng-container>

                <ng-container *ngIf="displayDefinePasswordGroup">

                    <div class="modal-body" [attr.id]="'modal-password-desc'">
                        <!--authenticated with temp password & set new password-->
                        <div formGroupName="definePasswordGroup">

                            <p>{{'PROFIL_TMP_PASSWORD_ALREADY_A_PROFIL'|translate}}</p>

                            <div [class.has-error]="httpErrors != null">
                                <label *ngIf="httpErrors != null" for="newPassword">
                                    <span class="help-block" tabindex="0">
                                        <app-http-error [errors]="httpErrors" [isGenericBlocError]="false"></app-http-error>
                                    </span>
                                </label>
                            </div>

                            <div class="form-horizontal m-t-4 m-b-4">
                                <div class="form-group">
                                    <label for="newPassword" class="col-md-6 control-label">
                                        <b>{{'PROFIL_NEW_PASSWORD' | translate}}</b>
                                    </label>
                                    <div class="col-md-18"
                                         [class.has-error]="isFieldInError('definePasswordGroup.newPassword') || isFieldInError('passwordStrength')">
                                        <label
                                            *ngIf="isFieldInError('definePasswordGroup.newPassword') || isFieldInError('passwordStrength')"
                                            for="newPassword">
                                            <app-error [form]="form" [submitted]="isFormSubmitted()"
                                                       [path]="'definePasswordGroup.newPassword'"></app-error>
                                            <app-error [form]="form" [submitted]="isFormSubmitted()"
                                                       [path]="'passwordStrength'"></app-error>
                                        </label>
                                        <input id="newPassword" class="form-control" name="newPassword" type="password"
                                               formControlName="newPassword" maxlength="32">
                                        <app-password-strength
                                            [password]="this.form.get('definePasswordGroup.newPassword').value"
                                            (strengthChanges)="onPasswordStrengthChanges($event)"
                                        ></app-password-strength>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label for="confirmNewPassword" class="col-md-6 control-label">
                                        <b>{{'PROFIL_CONFIRM_PASSWORD' | translate}}</b>
                                    </label>
                                    <div class="col-md-18"
                                         [class.has-error]="isFieldInError('definePasswordGroup.confirmNewPassword')">
                                        <label
                                            *ngIf="isFieldInError('definePasswordGroup.confirmNewPassword')"
                                            for="confirmNewPassword">
                                            <app-error [form]="form" [submitted]="isFormSubmitted()"
                                                       [path]="'definePasswordGroup.confirmNewPassword'"></app-error>
                                        </label>
                                        <input id="confirmNewPassword" class="form-control" name="confirmNewPassword"
                                               type="password"
                                               formControlName="confirmNewPassword" maxlength="32">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>

                <!-- Support Service -->
                <ng-container *ngIf="displayCustomerService">
                    <div class="modal-body" [attr.id]="'modal-password-desc'">
                        <p>{{customerServiceMsg|translate}}</p>
                    </div>
                </ng-container>


                <div class="modal-footer">
                    <div
                        *ngIf="displayAuthenticatePasswordGroup || displayDefinePasswordGroup"
                        class="btn-resp btn-resp-1colonne-inline">
                        <div *ngIf="showInputPassword" class="btn-resp-col btn-resp-demi">
                            <button class="btn btn-primary loadable"
                                    title="{{'BTN_PASSWORD_VALIDATION'|translate}}"
                                    type="submit"
                                    data-focus="initial"
                                    [class.loading]="loading"
                                    [attr.disabled]="loading ? true : null">
                                {{'BTN_PASSWORD_VALIDATION'|translate}}
                            </button>
                        </div>
                        <div class="btn-resp-col btn-resp-demi">
                            <button class="btn btn-default" data-dismiss="modal" title="{{'BTN_CANCEL'|translate}}"
                                    type="button">
                                {{'BTN_CANCEL'|translate}}
                            </button>
                        </div>
                    </div>
                    <button *ngIf="displayCustomerService" class="btn btn-primary"
                            data-dismiss="modal"
                            title="{{'BTN_ABANDON'|translate}}"
                            type="submit"
                            data-focus="initial">
                        {{'BTN_ABANDON'|translate}}
                    </button>
                </div>

                <div class="sr-only sr-only-focusable" tabindex="0" data-loop="end">{{'MODAL_END'|translate}}</div>

            </div>
        </form>
    </div>
</div>
