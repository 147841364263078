import {Component, Inject, OnInit} from '@angular/core';
import {VmdValidators} from '../../components/validators/vmd-validators';
import {FormBuilder} from '@angular/forms';
import {NavigationService} from '../../components/services/navigation.service';
import {ModalService} from '../../components/services/modal.service';
import {FormService} from '../../components/services/form.service';
import {TranslateService} from '@ngx-translate/core';
import {AccountRulesService} from '../../components/services/account-rules.service';
import {BaseInformationComponent} from './base-information.component';
import {IOdcConfig, ODC_CONFIG} from '../../odc-config';

const MODAL_NAME_FRV4AB_BC = 'FRV4AB_BC';
const MODAL_NAME_FRV4NS_ON = 'FRV4NS_ON';
const MODAL_NAME_CRI = 'CRI';
const MODAL_NAME_REER = 'REER';
const MODAL_NAME_FHSA = 'FHSA';
const MODAL_NAME_FHSA_COUNTRY = 'FHSA_COUNTRY';
const MODAL_NAME_RRNV = 'RRNV';
const MODAL_NAME_REEE = 'REEE';

@Component({
    selector: 'app-personal-informations',
    templateUrl: './personal-information.component.html'
})
export class PersonalInformationComponent extends BaseInformationComponent implements OnInit {

    showHomeAddress = true;

    constructor(public navigationService: NavigationService,
                protected fb: FormBuilder,
                public formService: FormService,
                public translate: TranslateService,
                protected accountRulesService: AccountRulesService,
                protected modalService: ModalService,
                @Inject(ODC_CONFIG) protected config: IOdcConfig) {
        super(navigationService, fb, formService, translate);
    }

    ngOnInit() {
        this.initRequester();

        this.subscriptions.push(this.modalService.validClickOnChoice1Emitted.subscribe((context: string) => {
            if (!this.isPleinEx() && context !== MODAL_NAME_FHSA_COUNTRY) {
                // OCE-7162
                const ddpRequester = this.formService.getDdpRequester();
                if (!this.formService.isReadOnly(ddpRequester.userDOB)) {
                    // for all 4 business rules, we have to empty the duserFirstNameb field if a modal cancel action occurs.
                    this.form.get('userDOB').setValue(null);
                    this.formService.setFormProperty(this.getRequesterPath('userDOB'), null);
                    this.submit();
                }
            }
        }));

        this.subscriptions.push(this.modalService.validClickOnChoice2Emitted.subscribe((context: string) => {

            if ((context === MODAL_NAME_FRV4AB_BC || context === MODAL_NAME_FRV4NS_ON) && this.formService.getForm().addLIF) {
                this.formService.setFormProperty('addLIF', false);
                this.formService.setFormProperty('lifTransfer', false);
                if (!this.isPleinEx() || this.processRules()) {
                    this.submit();
                }
            } else if ((context === MODAL_NAME_CRI) && this.formService.getForm().addLIRA) {
                this.formService.setFormProperty('addLIRA', false);
                this.formService.setFormProperty('addLIRASec', false);
                this.formService.setFormProperty('liraTransfer', false);
                if (!this.isPleinEx() || this.processRules()) {
                    this.submit();
                }
            } else if ((context === MODAL_NAME_REER) && this.formService.getForm().addRRSP) {
                this.formService.setFormProperty('addRRSP', false);
                this.formService.setFormProperty('addRRSPSec', false);
                this.formService.setFormProperty('addRRSPTer', false);
                this.formService.setFormProperty('rrspTransfer', false);
                if (!this.isPleinEx() || this.processRules()) {
                    this.submit();
                }
            } else if ((context === MODAL_NAME_RRNV) && this.formService.getForm().addFTARRSP) {
                this.formService.setFormProperty('addFTARRSP', false);
                this.formService.setFormProperty('addFTARRSPSec', false);
                this.formService.setFormProperty('addFTARRSPTer', false);
                this.formService.setFormProperty('ftarrspTransfer', false);
                if (!this.isPleinEx() || this.processRules()) {
                    this.submit();
                }
            } else if ((context === MODAL_NAME_REEE) && this.formService.getForm().addRESP) {
                this.formService.setFormProperty('addRESP', false);
                this.formService.setFormProperty('respTransfer', false);
                if (!this.isPleinEx() || this.processRules()) {
                    this.submit();
                }
            } else if ((context === MODAL_NAME_FHSA || context === MODAL_NAME_FHSA_COUNTRY) && this.formService.getForm().addFHSA) {
                this.formService.setFormProperty('addFHSA', false);
                this.formService.setFormProperty('fhsaTransfer', false);
                if (!this.isPleinEx() || this.processRules()) {
                    this.submit();
                }
            }
        }));

        this.initPhones();

        this.form = this.fb.group({
            formLang: [this.formService.getForm().requesters[this.requesterIndex].formLang],
            gender: [this.formService.getForm().requesters[this.requesterIndex].gender, [this.requiredValidator('gender')]],
            userFirstName: [this.formService.getForm().requesters[this.requesterIndex].userFirstName, [this.requiredValidator('userFirstName')]],
            userLastName: [this.formService.getForm().requesters[this.requesterIndex].userLastName, [this.requiredValidator('userLastName')]],
            userDOB: [this.formService.getForm().requesters[this.requesterIndex].userDOB, this.getUserDOBValidators()],
            userSIN: [this.formService.getForm().requesters[this.requesterIndex].userSIN, [this.requiredValidator('userSIN'), VmdValidators.sin]],
            phoneGroup: this.fb.group({
                userHomePhone: [this.formService.getForm().requesters[this.requesterIndex].userHomePhone, VmdValidators.phone],
                userOtherPhone: [this.formService.getForm().requesters[this.requesterIndex].userOtherPhone, VmdValidators.phone],
                userMobilePhone: [this.formService.getForm().requesters[this.requesterIndex].userMobilePhone, VmdValidators.phone]
            }, {validator: [VmdValidators.accountHolderPhonesMatchValidator, this.requiredGroupValidator(VmdValidators.accountHolderPhonesRequiredValidator)]}),
            userEmail: [this.formService.getForm().requesters[this.requesterIndex].userEmail, [this.requiredValidator('userEmail'), VmdValidators.email]],
            addressGroup: this.fb.group({
                homeAddress: this.fb.group({
                    userAddressStreet: [this.formService.getForm().requesters[this.requesterIndex].userAddressStreet,
                        [VmdValidators.addressStreetValidator(this.formService.isFieldRequired('userAddressStreet'))]],
                    userAddressUnit: [this.formService.getForm().requesters[this.requesterIndex].userAddressUnit, [VmdValidators.alphanumericWithSpace]],
                    userAddressCity: [this.formService.getForm().requesters[this.requesterIndex].userAddressCity, [this.requiredValidator('userAddressCity')]],
                    userAddressProv: [this.formService.getForm().requesters[this.requesterIndex].userAddressProv, [this.requiredValidator('userAddressProv')]],
                    userAddressPostalCode: [this.formService.getForm().requesters[this.requesterIndex].userAddressPostalCode, [this.requiredValidator('userAddressPostalCode')]],
                    userAddressCountry: [this.formService.getForm().requesters[this.requesterIndex].userAddressCountry, [this.requiredValidator('userAddressCountry')]],
                }, {validator: [VmdValidators.homeAddressZipCodeValidator, this.requiredGroupValidator(VmdValidators.homeAddressZipCodeRequired)]}),
                mailingAddress: this.fb.group({
                    userAddressStreetSec: [this.formService.getForm().requesters[this.requesterIndex].userAddressStreetSec,
                        [VmdValidators.addressStreetValidator(this.formService.isFieldRequired('userAddressStreetSec'))]],
                    userAddressUnitSec: [this.formService.getForm().requesters[this.requesterIndex].userAddressUnitSec, [VmdValidators.alphanumericWithSpace]],
                    userAddressCitySec: [this.formService.getForm().requesters[this.requesterIndex].userAddressCitySec, [this.requiredValidator('userAddressCitySec')]],
                    userAddressProvSec: [this.formService.getForm().requesters[this.requesterIndex].userAddressProvSec, [this.requiredValidator('userAddressProvSec')]],
                    userAddressPostalCodeSec: [this.formService.getForm().requesters[this.requesterIndex].userAddressPostalCodeSec, [this.requiredValidator('userAddressPostalCodeSec')]],
                    userAddressCountrySec: [this.formService.getForm().requesters[this.requesterIndex].userAddressCountrySec, [this.requiredValidator('userAddressCountrySec')]],
                }, {validator: [VmdValidators.mailingAddressZipCodeValidator, this.requiredGroupValidator(VmdValidators.mailingAddressZipCodeRequired)]}),
            }, {validator: VmdValidators.addressMatchValidator}),
            jointSecondAccountholderSpouse: [this.formService.getForm().jointSecondAccountholderSpouse, [this.requiredValidator('jointSecondAccountholderSpouse')]],
            isSameAddressAsFirstIndicator: [this.formService.getForm().requesters[this.requesterIndex].isSameAddressAsFirstIndicator, [this.requiredValidator('isSameAddressAsFirstIndicator')]]
        }, {validators: [VmdValidators.validMultipleHoldersProvince(this.formService.getForm().requesters, this.formService.isPrintMode(), this.requesterIndex, this.isJointAccount())]});

        // validation when loading the form

        if (!this.isFullFields()) {
            this.updateControlEnabled(this.form.get('userDOB'), false);
            this.updateControlEnabled(this.form.get('userSIN'), false);
            this.updateControlEnabled(this.form.get('phoneGroup.userOtherPhone'), false);
            this.updateControlEnabled(this.form.get('addressGroup'), false);
        }

        if (!this.isFieldUserMobileShown()) {
            this.updateControlEnabled(this.form.get('phoneGroup.userMobilePhone'), false);
        }

        // disable mailingAddress group by default
        this.updateControlEnabled(this.form.get('jointSecondAccountholderSpouse'), this.isPleinExJointAccountFirstAccountholderOfTwo());

        this.updateControlEnabled(this.form.get('isSameAddressAsFirstIndicator'), this.isOtherPersonThanFirstAccountHolder() || this.isEnfRefOrEenfEsQualites());

        const mailingAddressHasValues = this.form.get('addressGroup.mailingAddress.userAddressStreetSec').value ||
            this.form.get('addressGroup.mailingAddress.userAddressCitySec').value ||
            this.form.get('addressGroup.mailingAddress.userAddressProvSec').value ||
            this.form.get('addressGroup.mailingAddress.userAddressPostalCodeSec').value;
        this.toggleMailingAddress(mailingAddressHasValues);

        this.form.get('isSameAddressAsFirstIndicator').valueChanges.subscribe(value => {
            this.toggleHomeAddress(!value);
        });

        this.form.get('jointSecondAccountholderSpouse').valueChanges.subscribe(value => {
            this.formService.setFormProperty('jointSecondAccountholderSpouse', value);
        });

        if (this.requesterIndex !== 0) {
            const isSameAddress = (this.form.get('isSameAddressAsFirstIndicator').value !== null) ? this.form.get('isSameAddressAsFirstIndicator').value : true;
            this.toggleHomeAddress(!isSameAddress);
        }

        if (this.isVmdPortfolioManagementMandateOnly() || this.isSsdPortfolioManagementAdvisorProgram() ||
            (this.isGpdOrSfd() && this.form.get('addressGroup.homeAddress.userAddressCountry').value !== this.constants.COUNTRIES.UNITED_STATES)) {
            this.form.get('addressGroup.homeAddress.userAddressCountry').setValue(this.constants.COUNTRIES.CANADA);
        }
    }

    public getAccountHolderInformationTitle(): string {
        if (this.isClientTypeOtherAccount()) {
            return this.isEnfantMineurOrEsQualites()
                ? 'ODC_STEP_ACCOUNT_HOLDER_INFORMATION_TITLE'
                : 'ODC_STEP_CONTACT_INFORMATION_TITLE';
        } else {
            if (this.isPleinEx()) {
                return 'ODC_STEP_PERSONAL_INFORMATION_HOLDER_TITLE';
            } else {
                return this.isClientTypePersonalAccount()
                    ? 'ODC_STEP_PERSONAL_INFORMATION_HOLDER_TITLE'
                    : 'ODC_STEP_PERSONAL_INFORMATION_TITLE';
            }
        }
    }

    isFullFields(): boolean {
        return this.formService.getForm().cltType === this.constants.CLT_TYPE_INDIVIDU ||
            (this.isPleinEx() && this.formService.getForm().cltType === this.constants.CLT_TYPE_CONJOINT) ||
            (this.isPleinEx() && this.isEnfantMineurOrEsQualites() && this.formService.getForm().otherAddSpouse);
    }

    isEnfantMineurOrEsQualites(): boolean {
        return this.formService.getForm().cltType === this.constants.CLT_TYPE_ENF_REF || this.formService.getForm().cltType === this.constants.CLT_TYPE_ENF_ES_QUALITES;
    }

    isPleinExJointAccountFirstAccountholderOfTwo(): boolean {
        return this.isPleinEx()
            && this.formService.getForm().cltType === this.constants.CLT_TYPE_CONJOINT
            && this.requesterIndex === 0
            && this.formService.getForm().jointActPeople === 2;
    }

    isOtherPersonThanFirstAccountHolder(): boolean {
        return this.isPleinEx()
            && (this.requesterIndex === 1 || this.requesterIndex === 2)
            && this.formService.getForm().jointActPeople >= 2;
    }

    toggleHomeAddress(toggle: boolean) {
        this.showHomeAddress = toggle;
        this.updateControlEnabled(this.form.get('addressGroup.homeAddress'), this.showHomeAddress);
        this.resetHomeAddressValue(this.showHomeAddress);

        if (!this.showHomeAddress) {
            this.toggleMailingAddress(false);
        }

        // if mailing address has values, turn show/enable mailingAddress group
        if (this.form.get('addressGroup.mailingAddress.userAddressStreetSec').value || this.form.get('addressGroup.mailingAddress.userAddressCitySec').value ||
            this.form.get('addressGroup.mailingAddress.userAddressProvSec').value || this.form.get('addressGroup.mailingAddress.userAddressPostalCodeSec').value) {
            this.toggleMailingAddress(true);
        }
    }

    resetHomeAddressValue(show: boolean) {

        if (!show) {
            this.formService.setFormProperty(this.getRequesterPath('userAddressStreet'), null);
            this.formService.setFormProperty(this.getRequesterPath('userAddressUnit'), null);
            this.formService.setFormProperty(this.getRequesterPath('userAddressCity'), null);
            this.formService.setFormProperty(this.getRequesterPath('userAddressProv'), null);
            this.formService.setFormProperty(this.getRequesterPath('userAddressPostalCode'), null);
            if (this.requesterIndex === 0) {
                this.formService.setFormProperty(this.getRequesterPath('userAddressCountry'), this.constants.COUNTRIES.CANADA);
            } else {
                const isSameAddress = (this.form.get('isSameAddressAsFirstIndicator').value !== null) ? this.form.get('isSameAddressAsFirstIndicator').value : true;
                if (isSameAddress) {
                    this.formService.setFormProperty(this.getRequesterPath('userAddressCountry'), this.formService.getForm().requesters[0].userAddressCountry);
                } else {
                    this.formService.setFormProperty(this.getRequesterPath('userAddressCountry'), this.form.get('addressGroup.homeAddress.userAddressCountry').value);
                }
            }
        } else {

            this.formService.setFormProperty(this.getRequesterPath('userAddressStreet'), this.form.get('addressGroup.homeAddress.userAddressStreet').value);
            this.formService.setFormProperty(this.getRequesterPath('userAddressUnit'), this.form.get('addressGroup.homeAddress.userAddressUnit').value);
            this.formService.setFormProperty(this.getRequesterPath('userAddressCity'), this.form.get('addressGroup.homeAddress.userAddressCity').value);
            this.formService.setFormProperty(this.getRequesterPath('userAddressProv'), this.form.get('addressGroup.homeAddress.userAddressProv').value);
            this.formService.setFormProperty(this.getRequesterPath('userAddressPostalCode'), this.form.get('addressGroup.homeAddress.userAddressPostalCode').value);
            this.formService.setFormProperty(this.getRequesterPath('userAddressCountry'), this.form.get('addressGroup.homeAddress.userAddressCountry').value);
        }
    }

    processRules(): boolean {
        return this.isPleinEx()
            && (
                this.accountRulesService.processFRVRule4AB_BC()
                || this.accountRulesService.processFRVRule4NS_ON()
                || this.accountRulesService.processCRIRule()
                || this.accountRulesService.processREERRule()
                || this.accountRulesService.processFHSARule()
                || this.accountRulesService.processFHSARuleCountry()
                || this.accountRulesService.processRRNVRule()
                || this.accountRulesService.processRESPSecondAccountholderSpouseRule()
            );
    }

    submit() {

        if (this.formService.isQuebecResident()) {
            this.modalService.triggerEnglishQuebecResidentModal();
        }

        if (this.accountRulesService.processFRVRule4AB_BC()) {
            this.modalService.openModal(
                'ODC_STEP_PERSONAL_INFORMATION_MODAL_FRV4AB_BC_TITLE',
                'ODC_STEP_PERSONAL_INFORMATION_MODAL_FRV4AB_BC',
                '#modal-alert-personal-information',
                MODAL_NAME_FRV4AB_BC
            );
        } else if (this.accountRulesService.processFRVRule4NS_ON()) {
            this.modalService.openModal(
                'ODC_STEP_PERSONAL_INFORMATION_MODAL_FRV4NS_ON_TITLE',
                'ODC_STEP_PERSONAL_INFORMATION_MODAL_FRV4NS_ON',
                '#modal-alert-personal-information',
                MODAL_NAME_FRV4NS_ON
            );
        } else if (this.accountRulesService.processCRIRule()) {
            this.modalService.openModal(
                'ODC_STEP_PERSONAL_INFORMATION_MODAL_CRI_TITLE',
                'ODC_STEP_PERSONAL_INFORMATION_MODAL_CRI',
                '#modal-alert-personal-information',
                MODAL_NAME_CRI
            );
        } else if (this.accountRulesService.processREERRule()) {
            this.modalService.openModal(
                'ODC_STEP_PERSONAL_INFORMATION_MODAL_REER_TITLE',
                'ODC_STEP_PERSONAL_INFORMATION_MODAL_REER',
                '#modal-alert-personal-information',
                MODAL_NAME_REER
            );
        } else if (this.accountRulesService.processFHSARule()) {
            this.modalService.openModal(
                'ODC_STEP_PERSONAL_INFORMATION_MODAL_FHSA_TITLE',
                'ODC_STEP_PERSONAL_INFORMATION_MODAL_FHSA',
                '#modal-alert-personal-information',
                MODAL_NAME_FHSA
            );
        } else if (this.accountRulesService.processFHSARuleCountry()) {
            this.modalService.openModal(
                'ODC_STEP_PERSONAL_INFORMATION_MODAL_FHSA_COUNTRY_TITLE',
                'ODC_STEP_PERSONAL_INFORMATION_MODAL_FHSA_COUNTRY',
                '#modal-alert-personal-information',
                MODAL_NAME_FHSA_COUNTRY
            );
        } else if (this.accountRulesService.processRRNVRule()) {
            this.modalService.openModal(
                'ODC_STEP_PERSONAL_INFORMATION_MODAL_RRNV_TITLE',
                'ODC_STEP_PERSONAL_INFORMATION_MODAL_RRNV',
                '#modal-alert-personal-information',
                MODAL_NAME_RRNV
            );
        } else if (this.accountRulesService.processRESPSecondAccountholderSpouseRule()) {
            this.modalService.openModal(
                null,
                null,
                '#modal-alert-personal-information-second-accountholder-spouse',
                MODAL_NAME_REEE
            );
        } else {
            this.submitForm();
        }
    }

    isCountryReadOnly(): boolean {
        return this.isGpdOrSfd() || this.isSsdPortfolioManagementAdvisorProgram() || this.isVmdPortfolioManagementMandateOnly() || !this.isPleinEx();
    }

    isEnfRefOrEenfEsQualites(): boolean {
        return this.isPleinEx() && this.requesterIndex === 1 &&
            (this.formService.getForm().cltType === this.constants.CLT_TYPE_ENF_REF ||
                this.formService.getForm().cltType === this.constants.CLT_TYPE_ENF_ES_QUALITES);
    }

    isJointAccount(): boolean {
        return this.formService.getForm().cltType === this.constants.CLT_TYPE_CONJOINT;
    }

    isFieldUserMobileShown(): boolean {
        return this.isFullFields() && (this.isPleinEx() || this.isClientTypePersonalAccount());
    }

    isUpdateAccount(): boolean {
        return this.formService.getForm().newExistAct === this.constants.ACCOUNT_UPDATE_CLIENT;
    }

    private getUserDOBValidators() {
        return [this.requiredValidator('userDOB'), VmdValidators.dateOfBirthMajor, VmdValidators.dateOfBirthYoung, VmdValidators.notADateOfBirthYoung, VmdValidators.date];
    }

    private initPhones() {
        this.formService.getForm().requesters[this.requesterIndex].userHomePhone = this.numbersOnly(this.formService.getForm().requesters[this.requesterIndex].userHomePhone);
        this.formService.getForm().requesters[this.requesterIndex].userOtherPhone = this.numbersOnly(this.formService.getForm().requesters[this.requesterIndex].userOtherPhone);
        this.formService.getForm().requesters[this.requesterIndex].userMobilePhone = this.numbersOnly(this.formService.getForm().requesters[this.requesterIndex].userMobilePhone);

    }

    private numbersOnly(value: string): string {
        const regex = /([^0-9]+)/gi;
        return value ? value.replace(regex, '') : value;
    }

}
