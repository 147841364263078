import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {RecaptchaComponent} from '../../../login/recaptcha.component';
import {TranslateService} from '@ngx-translate/core';
import {FormService} from '../../../../components/services/form.service';
import {ModalService} from '../../../../components/services/modal.service';
import {ProfileService} from '../../../../components/services/profile.service';
import {WebException} from '../../../../components/models';
import {AbstractControl, FormBuilder, FormGroup, NgForm, Validators} from '@angular/forms';
import {HtmlSelectKV} from '../../../../components/models/html-select-k-v';
import {ListModelService} from '../../../../components/services/list-model.service';
import {CreateProfileBean} from '../../../../components/models/createProfileBean';
import {VmdValidators} from '../../../../components/validators/vmd-validators';

@Component({
    selector: 'app-modal-create-profile',
    templateUrl: './modal-create-profile.component.html'
})
export class ModalCreateProfileComponent implements OnInit {

    @ViewChild(RecaptchaComponent) recaptcha: RecaptchaComponent;
    @ViewChild('httpError') httpErrorDiv!: ElementRef;
    @ViewChild(NgForm) ngForm: NgForm;

    email: string;
    loading = false;
    emailSent: boolean = false;
    httpErrors: any;
    renderRecaptcha: boolean = false;
    securityQuestionList: HtmlSelectKV[];
    displayCreateProfileFormGroup = false;
    form: FormGroup = new FormGroup({});

    constructor(public translate: TranslateService,
                protected fb: FormBuilder,
                private formService: FormService,
                private modalService: ModalService,
                private listModelService: ListModelService,
                private profileService: ProfileService) {
    }

    ngOnInit(): void {

        this.form = this.fb.group({
            securityQuestion: [null, [Validators.required]],
            securityAnswer: [null, [Validators.required, Validators.minLength(5)]]
        });

        this.modalService.showCreateProfile.subscribe(() => {
            this.displayCreateProfileFormGroup = true;
            this.resetModal();
            this.email = this.formService.getForm().requesters[0].userEmail;
            this.renderRecaptcha = true;
        });

        this.listModelService.getListModel('questionSecuriteList', data => this.securityQuestionList = data);
    }

    private submit() {
        this.httpErrors = null;
        this.loading = true;

        const createProfileBean = new CreateProfileBean({
                lang: this.translate.currentLang,
                email: this.email,
                securityQuestion: this.form.get('securityQuestion').value,
                securityAnswer: this.form.get('securityAnswer').value,
                reCaptchaResponse: this.recaptcha.getRecaptchaToken()
            }
        );

        this.profileService.createProfile(createProfileBean).subscribe(() => {

                this.emailSent = true;
            },
            (error: WebException) => {
                this.httpErrors = error;
            })
            .add(() => {
                this.loading = false;
                this.resetRecaptcha();
            });
    }

    resetModal() {
        this.httpErrors = null;
        this.emailSent = false;
        this.form.reset();
        this.resetRecaptcha();
    }

    resetRecaptcha() {
        if (this.recaptcha) {
            this.recaptcha.reset();
        }
    }

    submitForm() {
        this.markFormFieldsAsTouched(this.form);
        if (this.form.valid) {
            this.submit();
        }
    }

    private markFormFieldsAsTouched(control: FormGroup): void {
        control.get('securityQuestion').markAsTouched();
        control.get('securityAnswer').markAsTouched();
    }

    isFieldInError(fieldName: string) {
        const control: AbstractControl = this.form.get(fieldName);

        if (control === null) {
            return false;
        }

        return control.touched && control.invalid &&
            ((control.hasError(VmdValidators.required.name) && this.isFormSubmitted()) ||
                !control.hasError(VmdValidators.required.name));
    }

    isFormSubmitted(): boolean {
        if (this.ngForm) {
            return this.ngForm.submitted;
        }

        return false;
    }

    onClosingModal() {
        this.displayCreateProfileFormGroup = false;
    }
}
