export enum ApiEndpoints {

    AuthenticationEntryPoint = 'api/authenticate',

    ExternalSaveForm = 'api/save',
    InternalSaveForm = 'api/internal/save',

    ExternalSaveCompletedForm = 'api/save-completed',
    InternalSaveCompletedForm = 'api/internal/save-completed',

    ExternalCompleteForm = 'api/complete',
    InternalCompleteForm = 'api/internal/complete',

    ExternalSaveTransmittedForm = 'api/save-transmitted',
    InternalSaveTransmittedForm = 'api/internal/save-transmitted',

    ExternalTransmitForm = 'api/transmit',
    InternalTransmitForm = 'api/internal/transmit',

    InternalPrintForm = 'api/internal/print',

    InternalCompareSavedForm = 'api/internal/compare-saved-form',

    InternalRequestDiff = 'api/internal/request-diff',

    InternalPdfDownloadForms = 'api/internal/pdf/download/ODC_Forms',
    InternalPdfDownloadAdditionalForms = 'api/internal/pdf/download/ODC_Additional_Forms',

    InternalSessionUsername = 'api/internal/session/user-name',

    InternalESignCaisseSubmit = 'api/internal/esign/caisse/submit',

    ExternalESignInProgress = 'api/esign/progress',
    InternalESignInProgress = 'api/internal/esign/progress',

    ExternalAttachedDocumentValidation = 'api/attachedDocumentValidation',
    InternalAttachedDocumentValidation = 'api/internal/attachedDocumentValidation',

    ExternalRecover = 'api/recover',
    InternalRecover = 'api/internal/recover',

    ExternalVerifyIdentityFile = 'api/verifyIdentityFile',
    InternalVerifyIdentityFile = 'api/internal/verifyIdentityFile',

    ExternalVerifyAccountStatement = 'api/verifyAccountStatement',
    InternalVerifyAccountStatement = 'api/internal/verifyAccountStatement',

    ExternalCheckFileContent = 'api/checkFileContent',

    InternalCheckFileContent = 'api/internal/checkFileContent',

    InternalPersonalInformation = 'api/internal/personal-information',

    InternalClientInformation = 'api/internal/client/information',

    ExternalPdfESignEmail = 'api/pdf/esign-mail',
    InternalPdfESignEmail = 'api/internal/pdf/esign-mail',

    ExternalPdfTypeEmail = 'api/pdf/type-email',
    InternalPdfTypeEmail = 'api/internal/pdf/type-email',

    ExternalESignUrl = 'api/esign/url',
    InternalESignUrl = 'api/internal/esign/url'
}
