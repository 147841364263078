import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {FormService} from '../../../../../components/services/form.service';
import {ModalService} from '../../../../../components/services/modal.service';
import {WebException} from '../../../../../components/models';
import {ProfileService} from '../../../../../components/services/profile.service';
import {RecaptchaComponent} from '../../../../login/recaptcha.component';

@Component({
    selector: 'app-modal-forgot-password',
    templateUrl: './modal-forgot-password.component.html'
})
export class ModalForgotPasswordComponent implements OnInit {

    @ViewChild(RecaptchaComponent) recaptcha: RecaptchaComponent;
    @ViewChild('httpError') httpErrorDiv!: ElementRef;

    email: string;
    loading = false;
    emailSent: boolean = false;
    httpErrors: any;
    renderRecaptcha: boolean = false;

    constructor(public translate: TranslateService,
                private formService: FormService,
                private modalService: ModalService,
                private profileService: ProfileService) {
    }

    ngOnInit(): void {

        this.modalService.showForgotPassword.subscribe(() => {
            this.resetModal();
            this.email = this.formService.getForm().requesters[0].userEmail;
            this.renderRecaptcha = true;
        });
    }

    submit() {
        this.httpErrors = null;
        this.loading = true;

        this.profileService.forgotPassword(this.translate.currentLang, this.email, this.recaptcha.getRecaptchaToken()).subscribe(() => {

                this.emailSent = true;
            },
            (error: WebException) => {
                this.httpErrors = error;
            })
            .add(() => {
                this.loading = false;
                this.resetRecaptcha();
            });
    }

    resetModal() {
        this.httpErrors = null;
        this.emailSent = false;
        this.resetRecaptcha();
    }

    resetRecaptcha() {
        if (this.recaptcha) {
            this.recaptcha.reset();
        }
    }
}
