export const JOINT_ACCOUNT_ROUTES_CONFIG: any = [
    {
        showTab: false,
        label: 'ODC_NAV_STEP_PREPARATION',
        items: [
            {
                label: 'ODC_NAV_STEP_PREPARATION_BEGIN',
                path: 'ODC_NAV_STEP_PREPARATION_BEGIN_PERSONAL_ACCOUNT_PATH',
                aside: 'ASIDE_PREPARATION',
                component: 'BeginComponent'
            }, {
                label: 'ODC_NAV_STEP_PREPARATION_CLIENT_TYPE',
                path: 'ODC_NAV_STEP_PREPARATION_CLIENT_TYPE_PERSONAL_ACCOUNT_PATH',
                aside: 'ASIDE_PREPARATION',
                component: 'ClientTypeComponent'
            }
        ],
    }, {
        showTab: true,
        label: 'ODC_NAV_STEP_ACCOUNTS_CHOICE',
        items: [
            {
                label: 'ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS',
                path: 'ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_JOINT_ACCOUNT_PATH',
                component: 'JointRelatedQuestionsComponent'
            }, {
                label: 'ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNTS_CHOICE',
                path: 'ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNTS_CHOICE_JOINT_ACCOUNT_PATH',
                component: 'AccountsChoiceComponent',
                disable: true
            }
        ]
    }, {
        showTab: true,
        label: 'ODC_NAV_STEP_PERSONAL_INFORMATION',
        items: [
            {
                label: 'ODC_NAV_STEP_PERSONAL_INFORMATION_CLIENT',
                path: 'ODC_NAV_STEP_PERSONAL_INFORMATION_CLIENT_JOINT_ACCOUNT_PATH',
                component: 'PersonalInformationComponent'
            }
        ]
    }, {
        showTab: true,
        label: 'ODC_NAV_STEP_TRANSFER_REQUEST',
        items: [
            {
                label: 'ODC_NAV_STEP_TRANSFER_REQUEST_TRANSFER_REQUEST_CHOICE',
                path: 'ODC_NAV_STEP_TRANSFER_REQUEST_TRANSFER_REQUEST_CHOICE_JOINT_ACCOUNT_PATH',
                component: 'TransferRequestChoiceComponent'
            }
        ]
    }, {
        showTab: true,
        label: 'ODC_NAV_STEP_TRANSMISSION',
        items: [
            {
                label: 'ODC_NAV_STEP_TRANSMISSION_TRANSMISSION_KIT_DOWNLOAD',
                path: 'ODC_NAV_STEP_TRANSMISSION_TRANSMISSION_KIT_DOWNLOAD_JOINT_ACCOUNT_PATH',
                component: 'TransmissionAdditionalFormsComponent',
            }
        ]
    }, {
        showTab: false,
        label: 'ODC_NAV_STEP_CONFIRMATION',
        items: [
            {
                label: 'ODC_NAV_STEP_CONFIRMATION_CONFIRMATION',
                path: 'ODC_NAV_STEP_CONFIRMATION_CONFIRMATION_JOINT_ACCOUNT_PATH',
                aside: 'ASIDE_CONFIRMATION',
                component: 'ConfirmationComponent'
            }, {
                label: 'ODC_NAV_STEP_CONFIRMATION_CONFIRMATION',
                path: 'ODC_NAV_STEP_CONFIRMATION_CONFIRMATION_CAISSE_JOINT_ACCOUNT_PATH',
                aside: 'ASIDE_CONFIRMATION',
                component: 'ConfirmationCaisseComponent',
                disable: true,
                hideAsideConsult: true
            }
        ]
    }
];
