<h1 xmlns="http://www.w3.org/1999/html">{{ 'ODC_LOGIN_TITRE_PAGE' | translate }}</h1>

<div class="row">

    <div class="col-md-16">
        <div class="panel panel-simple">
            <div id="questionnaire-content" class="panel-body" tabindex="0">

                <form [formGroup]="form" ngForm novalidate (submit)="onSubmit()">

                    <p *ngIf="form.get('login').enabled" class="m-b-4">{{ 'ODC_LOGIN_EXPLANATION' | translate }}</p>

                    <p *ngIf="form.get('passwords').enabled" [class.m-b-4]="true">{{'PROFIL_TMP_PASSWORD_ALREADY_A_PROFIL' | translate}}</p>

                    <app-error-list [form]="form" [ngFormRef]="ngForm"></app-error-list>
                    <app-http-error [errors]="httpErrors"></app-http-error>

                    <div *ngIf="!showList" class="grid-10">
                        <div *ngIf="form.get('login').enabled" formGroupName="login">

                            <app-odc-field
                                [type]="constants.FIELD_TYPES.TEXT"
                                [form]="form.get('login')"
                                [submitted]="isFormSubmitted()"
                                [label]="'ODC_LOGIN_EMAIL' | translate"
                                [id]="'courriel'"
                                [name]="'courriel'"
                                [path]="'courriel'"
                                [binding]="null"
                                [attr]="{maxlength: 64}"
                            ></app-odc-field>

                            <app-odc-field
                                [type]="constants.FIELD_TYPES.PASSWORD"
                                [form]="form.get('login')"
                                [submitted]="isFormSubmitted()"
                                [label]="'ODC_LOGIN_PASSWORD' | translate"
                                [id]="'motDePasse'"
                                [name]="'motDePasse'"
                                [path]="'motDePasse'"
                                [binding]="null"
                                [attr]="{maxlength: 32}"
                            ></app-odc-field>

                        </div>

                        <div *ngIf="form.get('passwords').enabled" formGroupName="passwords">

                            <app-odc-field
                                [type]="constants.FIELD_TYPES.PASSWORD"
                                [form]="form.get('passwords')"
                                [submitted]="isFormSubmitted()"
                                [label]="'PROFIL_NEW_PASSWORD' | translate"
                                [id]="'newPassword'"
                                [name]="'newPassword'"
                                [path]="'newPassword'"
                                [binding]="null"
                                [attr]="{maxlength: 32}"
                                [pathPasswordStrengthIndicator]="'passwordStrength'"
                            ></app-odc-field>

                            <app-odc-field
                                [type]="constants.FIELD_TYPES.PASSWORD"
                                [form]="form.get('passwords')"
                                [submitted]="isFormSubmitted()"
                                [label]="'PROFIL_CONFIRM_PASSWORD' | translate"
                                [id]="'confirmNewPassword'"
                                [name]="'confirmNewPassword'"
                                [path]="'confirmNewPassword'"
                                [binding]="null"
                                [attr]="{maxlength: 32}"
                            ></app-odc-field>

                        </div>

                        <div class="btn-resp btn-resp-1colonne-inline m-t-4">
                            <div class="btn-resp-col btn-resp-demi">
                                <button type="submit"
                                        class="btn btn-primary loadable"
                                        id="test"
                                        [class.loading]="loading"
                                        [attr.disabled]="loading ? true : null">
                                    <span *ngIf="form.get('login').enabled">
                                        {{ 'ODC_LOGIN_ACTION_LOGIN' | translate }}
                                    </span>
                                    <span *ngIf="form.get('passwords').enabled">
                                        {{ 'BTN_CONTINUE' | translate }}
                                    </span>
                                </button>
                                <button *ngIf="form.get('login').enabled"
                                        type="button"
                                        class="btn btn-link"
                                        (click)="goToForgotPassword()"
                                        [innerHTML]="'ODC_LOGIN_ACTION_RESET_PASSWORD' | translate"></button>
                            </div>
                        </div>
                        <br>

                        <app-recaptcha
                            [containerId]="'recaptcha-login'"
                            [langChange]="translate.onLangChange"
                            [lang]="translate.currentLang"
                        ></app-recaptcha>
                    </div>
                    <app-profile-account-opening-request-list></app-profile-account-opening-request-list>

                </form>

            </div>
        </div>
    </div>

    <div class="col-md-8">
        <app-aside></app-aside>
    </div>

</div>
