<app-header></app-header>

<main id="content" class="container m-t-3" tabindex="0">

    <router-outlet></router-outlet>

</main>

<!--<app-footer></app-footer>-->
<div id="modal-alert" class="modal" tabindex="-1" role="dialog" aria-labelledby="modal-alert-label" aria-describedby="modal-alert-desc">
    <div class="modal-dialog">
        <div class="modal-content" role="document" tabindex="0">
            <div class="modal-header">
                <h2 class="modal-title" id="modal-alert-label" data-loop="start" tabindex="0"></h2>
            </div>
            <div class="modal-body" id="modal-alert-desc"></div>
            <div class="modal-footer">
                <button class="btn btn-primary" data-dismiss="modal" title="{{'BTN_CONTINUE'|translate}}" type="submit"
                        data-focus="initial">{{'BTN_CONTINUE'|translate}}</button>
            </div>
            <div class="sr-only sr-only-focusable" tabindex="0" data-loop="end">{{'MODAL_END'|translate}}</div>
        </div>
    </div>
</div>

<div id="modal-alert-error" class="modal" tabindex="-1" role="dialog" aria-labelledby="modal-alert-label" aria-describedby="modal-alert-desc">
    <div class="modal-dialog">
        <div class="modal-content" role="document" tabindex="0">
            <div class="modal-header">
                <h2 class="modal-title" id="modal-alert-error-label" data-loop="start" tabindex="0"></h2>
            </div>
            <div class="modal-body" id="modal-alert-error-desc"></div>
            <b><p class="modal-specific-text"></p></b>
            <div class="modal-footer">
                <button class="btn btn-primary" data-dismiss="modal" title="{{'BTN_OK'|translate}}" type="submit" data-focus="initial"
                        (click)="modalService.emitValidClickOnChoice1()">
                    {{'BTN_OK'|translate}}
                </button>
            </div>
            <div class="sr-only sr-only-focusable" tabindex="0" data-loop="end">{{'MODAL_END'|translate}}</div>
        </div>
    </div>
</div>


<app-modal-password></app-modal-password>
<app-modal-forgot-password></app-modal-forgot-password>
<app-modal-create-profile></app-modal-create-profile>
<app-modal-personal-information-second-accountholder-spouse></app-modal-personal-information-second-accountholder-spouse>
<app-modal-pdf></app-modal-pdf>
<app-modal-add-entity></app-modal-add-entity>

<div id="modal-alert-personal-information" class="modal" tabindex="-1" role="dialog" aria-labelledby="modal-alert-personal-information-label"
     aria-describedby="modal-alert-personal-information-desc">
    <div class="modal-dialog">
        <div class="modal-content" role="document" tabindex="0">
            <div class="modal-header">
                <h2 class="modal-title" id="modal-alert-personal-information-label" data-loop="start" tabindex="0"></h2>
            </div>
            <div class="modal-body" id="modal-alert-personal-information-desc"></div>
            <div class="modal-footer">
                <button class="btn btn-default" data-dismiss="modal" title="{{'BTN_CANCEL'|translate}}" type="submit"
                        (click)="modalService.emitValidClickOnChoice1()">
                    {{'BTN_CANCEL'|translate}}
                </button>
                <button class="btn btn-primary" data-dismiss="modal" title="{{'BTN_CONTINUE'|translate}}" type="submit" data-focus="initial"
                        (click)="modalService.emitValidClickOnChoice2()">
                    {{'BTN_CONTINUE'|translate}}
                </button>
            </div>
            <div class="sr-only sr-only-focusable" tabindex="0" data-loop="end">{{'MODAL_END'|translate}}</div>
        </div>
    </div>
</div>


<div id="modal-esign" class="modal" tabindex="-1" role="dialog" aria-labelledby="modal-esign-label" aria-describedby="modal-esign-desc">
    <div class="modal-dialog modal-full-screen">
        <div class="modal-content" role="document" tabindex="0">
            <div class="modal-header">
                <h2 class="modal-title" id="modal-esign-label" data-loop="start" tabindex="0">
                    {{'ESIGN_MODAL_TITLE'|translate}}
                </h2>
                <button *ngIf="!esignLoading" type="submit" class="close" data-dismiss="modal">
                    <i class="icon-close"></i>
                </button>
            </div>
            <div class="modal-body" id="modal-esign-desc" [class.generation-pdf-01]="esignLoading">
                <div *ngIf="esignLoading" class="generation-pdf-01-text">
                    <h3>{{'ESIGN_MODAL_PAGE_TITLE'|translate}}</h3>
                    <div class="text-align-left">
                        <div class="pull-left m-r-3">
                            <img alt="" src="assets/img/loading.gif">
                        </div>
                        <div class="pull-left">{{'ESIGN_MODAL_WAITING' | translate}}</div>
                    </div>
                </div>

                <iframe *ngIf="!esignLoading" scrolling="yes"></iframe>
            </div>
            <div class="modal-footer">
                <span class="text-inline">
                    <span class="contactus p-l-3">
                        {{ 'SIDEBAR_ASSISTANCE_TITLE' | translate }}
                    </span>
                    <span class="m-l-1" itemscope itemtype="http://schema.org/BankOrCreditUnion">
                        <span itemprop="telephone">
                            <a href="tel:{{ 'SIDEBAR_ASSISTANCE_PHONE_LINK' | translate }}" class="lien-sans-soulignement">
                                {{ 'SIDEBAR_ASSISTANCE_PHONE' | translate }}
                                <span class="sr-only">{{ 'SR_ONLY_PHONE' | translate }}</span>
                            </a>
                        </span>
                    </span>
                    <span class="m-l-1">
                        {{ 'SIDEBAR_ASSISTANCE_SCHEDULE' | translate }}
                    </span>
                </span>
                <button class="btn btn-primary" data-dismiss="modal" title="{{'ESIGN_MODAL_BTN_NEXT_STEP_TOOLTIP'|translate}}" type="submit"
                        data-focus="initial">
                    {{'ESIGN_MODAL_BTN_NEXT_STEP'|translate}}
                </button>
            </div>
        </div>
    </div>
</div>

<div id="modal-esign-confirm-close" class="modal" tabindex="-1" role="dialog" aria-labelledby="modal-esign-confirm-close-label"
     aria-describedby="modal-esign-confirm-close-desc">
    <div class="modal-dialog">
        <div class="modal-content" role="document" tabindex="0">
            <div class="modal-header">
                <h2 class="modal-title" id="modal-esign-confirm-close-label" data-loop="start" tabindex="0">
                    {{'ESIGN_MODAL_TITLE'|translate}}
                </h2>
            </div>
            <div class="modal-body" id="modal-esign-confirm-close-desc">
                {{'ESIGN_MODAL_CONFIRM_CLOSE_BODY'|translate}}
            </div>
            <div class="modal-footer">
                <button class="btn btn-default" data-dismiss="modal" title="{{'GLOBAL_NO'|translate}}" type="submit"
                        (click)="modalService.emitEsignCloseConfirmPopup(false)">
                    {{'GLOBAL_NO'|translate}}
                </button>
                <button class="btn btn-primary" data-dismiss="modal" title="{{'BTN_CLOSE'|translate}}" type="submit" data-focus="initial"
                        (click)="modalService.emitEsignCloseConfirmPopup(true)">
                    {{'GLOBAL_YES'|translate}}
                </button>
            </div>
        </div>
    </div>
</div>

<div id="modal-alert-platform-disnat" class="modal" tabindex="-1" role="dialog" aria-labelledby="modal-alert-platform-disnat-label"
     aria-describedby="modal-alert-platform-disnat-desc">
    <div class="modal-dialog">
        <div class="modal-content" role="document" tabindex="0">
            <div class="modal-header">
                <h2 class="modal-title" id="modal-alert-platform-disnat-label" data-loop="start" tabindex="0"></h2>
            </div>
            <div class="modal-body" id="modal-alert-platform-disnat-desc"></div>
            <div class="modal-footer">
                <button class="btn btn-default" data-dismiss="modal" title="{{'BTN_CLOSE'|translate}}" type="submit"
                        (click)="modalService.emitValidClickOnPlatformBack()">
                    {{'BTN_BACK_PLATFORM_DISNAT'|translate}}
                </button>
                <button class="btn btn-primary" data-dismiss="modal" title="{{'BTN_CLOSE'|translate}}" type="submit" data-focus="initial"
                        (click)="modalService.emitValidClickOnPlatformContinue()">
                    {{'BTN_CONTINUE'|translate}}
                </button>
            </div>
            <div class="sr-only sr-only-focusable" tabindex="0" data-loop="end">{{'MODAL_END'|translate}}</div>
        </div>
    </div>
</div>

<div id="modal-session-timeout" class="modal" tabindex="-1" role="dialog" aria-labelledby="modal-session-timeout-label"
     aria-describedby="modal-session-timeout-desc">
    <div class="modal-dialog">
        <div class="modal-content" role="document" tabindex="0">
            <div class="modal-header">
                <h2 class="modal-title" id="modal-session-timeout-label" data-loop="start" tabindex="0">
                    {{'EXPIRATION_SESSION_TITLE' | translate}}
                </h2>
            </div>
            <div class="modal-body" id="modal-session-timeout-desc">
                <p [innerHTML]="getBodyExpirationSession()"></p>
                <p>
                    <strong>
                        <span class="timeout-counter" id="session-timeout-remaining">{{ countdown }}</span>
                    </strong>
                    {{'EXPIRATION_SESSION_BODY_TIMEOUT_2' | translate}}
                </p>
            </div>
            <div class="modal-footer">
                <button class="btn btn-default" data-dismiss="modal" title="{{'BTN_CLOSE'|translate}}" type="submit"
                        (click)="invalidateSession()">
                    {{'BTN_ABANDON'|translate}}
                </button>
                <button class="btn btn-primary" data-dismiss="modal" title="{{'BTN_CLOSE'|translate}}" type="submit" data-focus="initial"
                        (click)="extendSession()">
                    {{'BTN_EXTEND'|translate}}
                </button>
            </div>
            <div class="sr-only sr-only-focusable" tabindex="0" data-loop="end">{{'MODAL_END'|translate}}</div>
        </div>
    </div>
</div>

<div id="modal-video" class="modal" tabindex="-1" role="dialog" aria-labelledby="modal-video-label" aria-describedby="modal-video-desc">
    <div class="modal-dialog modal-lg">
        <div class="modal-content" role="document" tabindex="0">
            <div class="modal-header">
                <h2 class="modal-title" id="modal-video-label" data-loop="start" tabindex="0">
                    <span *ngIf="popupVideoParam">{{popupVideoParam.title|translate}}</span>
                </h2>
            </div>
            <div class="modal-body" id="modal-video-desc">
                <div id="video-player">
                    <iframe
                        id="player"
                        class="modal-video-iframe"
                        frameborder="0"
                        allowfullscreen="1"
                        allow="autoplay; encrypted-media"
                        title="YouTube video player"
                    ></iframe>
                </div>
            </div>
            <div class="modal-footer">
                <button class="btn btn-primary" data-dismiss="modal" title="{{'BTN_CLOSE'|translate}}" type="submit" data-focus="initial">
                    {{'BTN_CLOSE'|translate}}
                </button>
            </div>
            <div class="sr-only sr-only-focusable" tabindex="0" data-loop="end">{{'MODAL_END'|translate}}</div>
        </div>
    </div>
</div>

<div id="modal-alert-choice-attorney" class="modal" tabindex="-1" role="dialog" aria-labelledby="modal-alert-choice-attorney-label"
     aria-describedby="modal-alert-choice-attorney-desc">
    <div class="modal-dialog">
        <div class="modal-content" role="document" tabindex="0">
            <div class="modal-header">
                <h2 class="modal-title" id="modal-alert-choice-attorney-label" data-loop="start" tabindex="0"></h2>
            </div>
            <div class="modal-body" id="modal-alert-choice-attorney-desc"></div>
            <div class="modal-footer">
                <button class="btn btn-default" data-dismiss="modal" title="{{'BTN_CANCEL'|translate}}" type="submit"
                        (click)="modalService.emitValidClickOnChoice1()">
                    {{'BTN_CANCEL'|translate}}
                </button>
                <button class="btn btn-primary" data-dismiss="modal" title="{{'BTN_CONTINUE'|translate}}" type="submit" data-focus="initial"
                        (click)="modalService.emitValidClickOnChoice2()">
                    {{'BTN_CONTINUE'|translate}}
                </button>
            </div>
            <div class="sr-only sr-only-focusable" tabindex="0" data-loop="end">{{'MODAL_END'|translate}}</div>
        </div>
    </div>
</div>

<div id="modal-continue-back" class="modal" tabindex="-1" role="dialog" aria-labelledby="modal-continue-back-label"
     aria-describedby="modal-continue-back-desc">
    <div class="modal-dialog">
        <div class="modal-content" role="document" tabindex="0">
            <div class="modal-header">
                <h2 class="modal-title" id="modal-continue-back-label" data-loop="start" tabindex="0"></h2>
            </div>
            <div class="modal-body" id="modal-continue-back-desc"></div>
            <div class="modal-footer">
                <button class="btn btn-default" data-dismiss="modal" title="{{'BTN_CANCEL'|translate}}" type="submit"
                        (click)="modalService.emitValidClickOnChoice1()">
                    {{'BTN_CANCEL'|translate}}
                </button>
                <button class="btn btn-primary" data-dismiss="modal" title="{{'BTN_CONTINUE'|translate}}" type="submit" data-focus="initial"
                        (click)="modalService.emitValidClickOnChoice2()">
                    {{'BTN_CONTINUE'|translate}}
                </button>
            </div>
            <div class="sr-only sr-only-focusable" tabindex="0" data-loop="end">{{'MODAL_END'|translate}}</div>
        </div>
    </div>
</div>

<div id="modal-alert-choice-transfer-request" class="modal" tabindex="-1" role="dialog"
     aria-labelledby="modal-alert-choice-transfer-request-label"
     aria-describedby="modal-alert-choice-transfer-request-desc">
    <div class="modal-dialog">
        <div class="modal-content" role="document" tabindex="0">
            <div class="modal-header">
                <h2 class="modal-title" id="modal-alert-choice-transfer-request-label" data-loop="start"
                    tabindex="0"></h2>
            </div>
            <div class="modal-body" id="modal-alert-choice-transfer-request-desc"></div>
            <div class="modal-footer">
                <button class="btn btn-primary" data-dismiss="modal" title="{{'BTN_OK'|translate}}" type="submit"
                        data-focus="initial"
                        (click)="modalService.emitValidClickOnChoice1()">
                    {{'BTN_OK'|translate}}
                </button>
            </div>
            <div class="sr-only sr-only-focusable" tabindex="0" data-loop="end">{{'MODAL_END'|translate}}</div>
        </div>
    </div>
</div>


<div id="modal-alert-most-recent-form-date" class="modal" tabindex="-1" role="dialog"
     aria-labelledby="modal-alert-most-recent-form-date-label"
     aria-describedby="modal-alert-most-recent-form-date-desc">
    <div class="modal-dialog">
        <div class="modal-content" role="document" tabindex="0">
            <div class="modal-header">
                <h2 class="modal-title" id="modal-alert-most-recent-form-date-label" data-loop="start"
                    tabindex="0">
                    {{'MOST_RECENT_FORM_MODAL_TITLE' | translate}}
                </h2>
            </div>
            <div class="modal-body" id="modal-alert-most-recent-form-date-desc">
                <p>{{ 'MOST_RECENT_FORM_MODAL_PARAGRAPH_1_BEFORE' | translate }}</p>
                <b><p class="modal-specific-text"></p></b>
                <p>{{'MOST_RECENT_FORM_MODAL_PARAGRAPH_2_BEFORE' | translate }}</p>
                <p>{{'MOST_RECENT_FORM_MODAL_PARAGRAPH_3_BEFORE' | translate }}</p>
            </div>
            <div class="modal-footer">
                <button class="btn btn-primary" data-dismiss="modal" title="{{'BTN_CONTINUE'|translate}}" type="submit"
                        data-focus="initial">{{'BTN_CONTINUE'|translate}}</button>
            </div>
            <div class="sr-only sr-only-focusable" tabindex="0" data-loop="end">{{'MODAL_END'|translate}}</div>
        </div>

    </div>
</div>

<div id="modal-alert-most-recent-form-date-after" class="modal" tabindex="-1" role="dialog"
     aria-labelledby="modal-alert-most-recent-form-date-after-label"
     aria-describedby="modal-alert-most-recent-form-date-after-desc">
    <div class="modal-dialog">
        <div class="modal-content" role="document" tabindex="0">
            <div class="modal-header">
                <h2 class="modal-title" id="modal-alert-most-recent-form-date-after-label" data-loop="start"
                    tabindex="0">
                    {{'MOST_RECENT_FORM_MODAL_TITLE' | translate}}
                </h2>
            </div>
            <div class="modal-body" id="modal-alert-most-recent-form-date-after-desc">
                <p>{{ 'MOST_RECENT_FORM_MODAL_PARAGRAPH_1' | translate }}</p>
                <b><p class="modal-specific-text"></p></b>
                <p>{{'MOST_RECENT_FORM_MODAL_PARAGRAPH_3' | translate }}</p>
                <p>{{'MOST_RECENT_FORM_MODAL_PARAGRAPH_AFTER' | translate }}</p>
            </div>
            <div class="modal-footer">
                <button class="btn btn-primary" data-dismiss="modal" title="{{'BTN_CONTINUE'|translate}}" type="submit"
                        data-focus="initial">{{'BTN_CONTINUE'|translate}}</button>
            </div>
            <div class="sr-only sr-only-focusable" tabindex="0" data-loop="end">{{'MODAL_END'|translate}}</div>
        </div>

    </div>
</div>

<div id="modal-alert-user-address-unstructured" class="modal" tabindex="-1" role="dialog"
     aria-labelledby="modal-alert-most-recent-form-date-after-label"
     aria-describedby="modal-alert-most-recent-form-date-after-desc">
    <div class="modal-dialog">
        <div class="modal-content" role="document" tabindex="0">
            <div class="modal-header">
                <h2 class="modal-title" id="modal-alert-user-address-unstructured-title" data-loop="start"
                    tabindex="0">
                    {{'UNSTRUCTURED_ADDRESS_TITLE' | translate}}
                </h2>
            </div>
            <div class="modal-body" id="modal-alert-user-address-unstructured-body">
                <p>{{ 'UNSTRUCTURED_ADDRESS_NOTICE' | translate }}</p>
            </div>
            <div class="modal-footer">
                <button class="btn btn-primary" data-dismiss="modal" title="{{'BTN_CONTINUE'|translate}}" type="submit"
                        data-focus="initial">{{'BTN_CONTINUE'|translate}}</button>
            </div>
            <div class="sr-only sr-only-focusable" tabindex="0" data-loop="end">{{'MODAL_END'|translate}}</div>
        </div>

    </div>
</div>

<div id="modal-alert-generic" class="modal" tabindex="-1" role="dialog">
    <div class="modal-dialog">
        <div class="modal-content" role="document" tabindex="0">
            <div class="modal-header">
                <h2 class="modal-title" data-loop="start"
                    tabindex="0">
                </h2>
            </div>
            <div class="modal-body">
            </div>
            <div class="modal-footer">
                <button class="btn btn-primary" data-dismiss="modal" title="{{'BTN_OK'|translate}}" type="submit"
                        data-focus="initial" (click)='modalService.emitValidClickOnChoice1()'>{{'BTN_OK'|translate}}</button>

                <button class="btn btn-default" data-dismiss="modal" title="{{'BTN_CANCEL'|translate}}" type="submit"
                        data-focus="initial">{{'BTN_CANCEL'|translate}}</button>
            </div>
            <div class="sr-only sr-only-focusable" tabindex="0" data-loop="end">{{'MODAL_END'|translate}}</div>
        </div>

    </div>
</div>
